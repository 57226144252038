import React from "react";
import tw from "twin.macro";
import { SectionHeading } from "../misc/Headings";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { SectionDescription } from "../misc/Typography";
import { PrimaryButton } from "../misc/Buttons";

const Row = tw.div`flex justify-center items-center lg:pt-16`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const TextColumn = tw.div`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-secondary-900 leading-snug xl:text-2xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const LearnMore = tw(PrimaryButton)`mt-8 inline-block`;

export default ({ features, heading, description, href }) => {
  return (
    <>
      <Row>
        <TextColumn>
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
          <FeatureList>
            {features.map((feature, index) => (
              <Feature key={index}>
                <FeatureIcon />
                <FeatureText>{feature}</FeatureText>
              </Feature>
            ))}
          </FeatureList>
        </TextColumn>
      </Row>
      <LearnMore as="a" href={href}>
        Learn more
      </LearnMore>
    </>
  );
};
