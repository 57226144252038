import JobTemplate from "./JobTemplate";
const mailTo =
  "mailto:careers@aspect.is?subject=Senior Full-Stack Engineer Application&body=Hi, I would like to apply for the open Senior Full-Stack Engineer Position. Here is my CV.";
export default () => (
  <JobTemplate
    title={"Senior Full-Stack Engineer"}
    imageUrl={
      "https://images.unsplash.com/photo-1554306274-f23873d9a26c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80"
    }
    mailTo={mailTo}
  />
);
