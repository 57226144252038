import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import OurAspects from "../components/features/OurAspects";
import AboutAspect from "../components/features/TwoColWithSideImage";

import AspectLaunchIllustration from "../images/button_launch.svg";
import { ReactComponent as UserCheckImageComponent } from "feather-icons/dist/icons/user-check.svg";
import { ReactComponent as ShareImageComponent } from "feather-icons/dist/icons/share-2.svg";
import { ReactComponent as BatteryChargingImageComponent } from "feather-icons/dist/icons/battery-charging.svg";
import { ReactComponent as CheckCircleImageComponent } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as CpuImageComponent } from "feather-icons/dist/icons/cpu.svg";
import { ReactComponent as UsersImageComponent } from "feather-icons/dist/icons/users.svg";
import HeaderDark from "../components/headers/dark";
import { defaultNavDark } from "../utils/navigation";

const Container = tw.div`max-w-screen-xl md:flex-row justify-between mx-auto`;

export default () => (
  <AnimationRevealPage disabled>
    <Container>
      <HeaderDark links={defaultNavDark(true)} />
    </Container>
    <AboutAspect
      subheading={"Aspect Launch"}
      heading={
        <>
          We want to <span tw="text-primary-500">inspire</span> our{" "}
          <span tw="text-primary-500">people</span> to do what inspires them so
          we can, <span tw="text-primary-500">together</span>, change any{" "}
          <span tw="text-primary-500">aspect</span> for the{" "}
          <span tw="text-primary-500">better</span>
        </>
      }
      imageSrc={AspectLaunchIllustration}
      textOnLeft={false}
      description={
        "Aspect was born in 2022 from sheer belief that we can achieve more by inspiring our people to do what they love. " +
        "Software engineering is an art and we want to help build the world of incredible software craftsmen. " +
        "We nurture the importance of details and excel the growth of our community by showing great passion and care. " +
        "Anyone who gets a chance to experience our liveliness and enthusiasm will understand the higher purpose we serve."
      }
    />
    <OurAspects
      heading={"Aspects that matter to us"}
      description={
        "Aspect was founded on the principle that we all deserve more from people and technology."
      }
      cards={[
        {
          ImageComponent: UserCheckImageComponent,
          title: "Accountability",
          description:
            "Accepting responsibility for our actions and inactions is the ultimate way to build trust internally and externally.",
        },
        {
          ImageComponent: ShareImageComponent,
          title: "Share Everything",
          description:
            "Being open and sharing everything: successes, failures, feelings, or doubts.",
        },
        {
          ImageComponent: BatteryChargingImageComponent,
          title: "Passion",
          description:
            "Having joy not just for the work itself but also for the people around us, so that everyone can be bold, innovative, and creative.",
        },
        {
          ImageComponent: CheckCircleImageComponent,
          title: "Excellence",
          description:
            "We are what we repeatedly do. Excellence requires constant adaptation, innovation, and vigilance in order to achieve success.",
        },
        {
          ImageComponent: CpuImageComponent,
          title: "Critical Thinking",
          description:
            "We analyse available facts, evidence, observations, and arguments to form a judgement.",
        },
        {
          ImageComponent: UsersImageComponent,
          title: "Teamwork",
          description:
            "We know that if we want to go far, we need to move together.",
        },
      ]}
    />
    <Footer />
  </AnimationRevealPage>
);
