import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link as RouterLink } from "react-router-dom";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
  RouterNavLink,
  PrimaryRouterNavLink,
} from "../headers/light.js";
import routes from "../../utils/routes";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${(p) => p.imageUrl});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-around items-center flex-col lg:flex-row`;
const CenterColumn = tw.div`flex flex-col items-center lg:block`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block text-center mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

export default ({
  imageUrl,
  heading = (
    <span>
      Elevate your <br />
      <SlantedBackground>career</SlantedBackground>
    </span>
  ),
}) => {
  const navLinks = [
    <NavLinks key={1}>
      <RouterNavLink to={routes.whatWeDo}>What We Do</RouterNavLink>
      <RouterNavLink to={routes.howWeWork}>How We Work</RouterNavLink>
      <RouterNavLink to={routes.clientStories}>Client Stories</RouterNavLink>
      {/*TODO: @skos add tech blog link*/}
      {/*<RouterNavLink to={routes.techBlog}>Tech Blog</RouterNavLink>*/}
      <RouterNavLink to={routes.aboutUs}>About Us</RouterNavLink>
      <RouterNavLink to={routes.careers}>Careers</RouterNavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryRouterNavLink to={routes.contactUs}>
        Contact Us
      </PrimaryRouterNavLink>
    </NavLinks>,
  ];

  return (
    <Container imageUrl={imageUrl}>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <CenterColumn>
            <Heading>{heading}</Heading>
          </CenterColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
