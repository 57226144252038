import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Footer from "components/footers/MiniCenteredFooter.js";
import BackgroundAsImageClientStories from "../components/hero/BackgroundAsImageClientStories";
import styled from "styled-components";
import MusicClientStory from "../components/stories/MusicClientStory";
import BusinessConsultantClientStory from "../components/stories/BusinessConsultantClientStory";
import CodeAutomationClientStory from "../components/stories/CodeAutomationClientStory";

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

export default () => (
  <AnimationRevealPage disabled>
    <BackgroundAsImageClientStories
      showContactUs
      primaryAction={false}
      heading={
        <>
          <span>
            Explore a selection of our
            <br />
            <SlantedBackground>
              cutting-edge software projects
            </SlantedBackground>
            <br />
            tailored to meet our clients' needs
            <br />
          </span>
        </>
      }
    />
    <CodeAutomationClientStory textOnLeft={true} />
    <MusicClientStory />
    <BusinessConsultantClientStory textOnLeft={true} />
    <Footer />
  </AnimationRevealPage>
);
