import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import Footer from "components/footers/MiniCenteredFooter.js";

import Technologies from "../components/features/DashedBorderSixFeatures";
import TeamIllustration from "../images/team_collaboration.svg";
import TeamAugmentationIllustration from "../images/team-augmentation.svg";
import TeachingIllustration from "../images/teaching.svg";
import { ReactComponent as ClosedBook } from "feather-icons/dist/icons/book.svg";
import { ReactComponent as FastForward } from "feather-icons/dist/icons/fast-forward.svg";
import { ReactComponent as EyeIcon } from "feather-icons/dist/icons/eye.svg";
import { ReactComponent as AwardIcon } from "feather-icons/dist/icons/award.svg";
import { ReactComponent as SaveIcon } from "feather-icons/dist/icons/save.svg";
import { ReactComponent as GitBranchIcon } from "feather-icons/dist/icons/git-branch.svg";
import TwoColumnWithPrimaryBackground from "../components/hero/TwoColumnWithPrimaryBackground";
import TwoColWithTwoHorizontalFeaturesAndButton from "../components/features/TwoColWithTwoHorizontalFeaturesAndButton";
import { ReactComponent as QuoteIconBase } from "../images/quotes-l.svg";
import ThoughtProcessIllustration from "../images/thought_process.svg";
import { StyledDiv } from "../helpers/AnimationRevealPage";

const Testimonial = tw.div`px-6 italic py-12 mb-12 sm:px-20 sm:py-16 focus:outline-none flex! flex justify-center h-full`;
const QuoteContainer = tw.div`relative`;
const QuoteIcon = tw(
  QuoteIconBase
)`absolute opacity-15 top-0 left-0 transform -translate-y-2 -translate-x-1/2 sm:-translate-x-full w-10 fill-current text-primary-500`;
const Quote = tw.blockquote`font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-left`;
const Container = tw(StyledDiv)`px-4 sm:px-8 max-w-screen-xl mx-auto`;
const HeaderContainer = tw(
  StyledDiv
)`min-h-0 px-4 sm:px-8 pt-8 pb-0 bg-primary-800`;

export default () => (
  <>
    <HeaderContainer>
      <TwoColumnWithPrimaryBackground
        heading="We thrive on complex challenges and enjoy optimizing things"
        imageSrc={ThoughtProcessIllustration}
        description={
          "Share the challenges you are facing and shift all the responsibilities to us. We are delighthed to leverage our expertise and tackle the most complex tasks."
        }
      />
    </HeaderContainer>
    <Container>
      <TwoColWithTwoHorizontalFeaturesAndButton
        id={"end-to-end"}
        subheading={"End-to-End Solution Model"}
        textOnLeft={false}
        heading={
          <>
            You own the business vision - we can take on the project{" "}
            <span tw="text-primary-500">ownership</span>
          </>
        }
        imageSrc={TeamIllustration}
        description={
          "With the End-to-End Model, Aspect’s team handles every stage of product development, from conceptualization to delivery - and is solely responsible for the team's performance."
        }
        features={[
          {
            Icon: EyeIcon,
            title: "Responsibility",
            description:
              "We take on all the responsibility so you don't have to worry about risk sharing.",
          },
          {
            Icon: AwardIcon,
            title: "Quality",
            description:
              "By having the project in our control we can guarantee quality standards.",
          },
        ]}
      />
      <TwoColWithTwoHorizontalFeaturesAndButton
        id={"augmentation"}
        subheading={"Team Augmentation Model"}
        textOnLeft={true}
        heading={
          <>
            Add highly experienced and hard-working engineers to your{" "}
            <span tw="text-primary-500">team</span>
          </>
        }
        imageSrc={TeamAugmentationIllustration}
        description={
          "Team augmentation is the process of hiring external talent to work as" +
          "          part of your internal team. Engineers as part-time or full-time contractors. By applying" +
          "          engineering staff augmentation, companies can close the knowledge gap," +
          "          speed up their time to hire and time to market, and access" +
          "          highly-qualified software engineers."
        }
        features={[
          {
            Icon: ClosedBook,
            title: "Experience",
            description: "Close the knowledge gap.",
          },
          {
            Icon: FastForward,
            title: "Speed up",
            description: "Speed up the time to hire and time to market.",
          },
        ]}
      />
      <TwoColWithTwoHorizontalFeaturesAndButton
        id={"consulting"}
        subheading={"Technical Consulting Model"}
        textOnLeft={false}
        heading={
          <>
            Optimize costs by making the correct{" "}
            <span tw="text-primary-500">technical decisions</span> with the help
            of our experts
          </>
        }
        imageSrc={TeachingIllustration}
        description={
          "Our technical consultancy services are provided by a team of engineering experts with hands-on expertise in helping you align technology needs with business goals. Our project management experts can assure a smooth implementation of the recommended changes and lead you through a successful transformation."
        }
        features={[
          {
            Icon: SaveIcon,
            title: "Save",
            description:
              "Save time and money by avoiding costly mistakes in areas where you lack experience.",
          },
          {
            Icon: GitBranchIcon,
            title: "Learn",
            description:
              "Permanently improve your technical knowledge by learning from us.",
          },
        ]}
      />
      <Technologies />
      <Testimonial>
        <QuoteContainer>
          <QuoteIcon />
          <Quote>"Learning never exhausts the mind."</Quote>
          Leonardo da Vinci
        </QuoteContainer>
      </Testimonial>
    </Container>
    <Footer />
  </>
);
