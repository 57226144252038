import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import aspectLogo from "../../images/aspect-logo.png";
import { ReactComponent as LinkedinIcon } from "feather-icons/dist/icons/linkedin.svg";
import { ReactComponent as FacebookIcon } from "feather-icons/dist/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "feather-icons/dist/icons/instagram.svg";
import { Link as RouterLink } from "react-router-dom";
import routes from "../../utils/routes";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-12`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw(
  RouterLink
)`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const FlexLink = tw(Link)`flex items-center justify-center`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <FlexLink to="/">
              <LogoImg src={aspectLogo} />
              <LogoText>Aspect</LogoText>
            </FlexLink>
          </LogoContainer>
          <LinksContainer>
            <Link to={routes.whatWeDo}>What We Do</Link>
            <Link to={routes.howWeWork}>How We Work</Link>
            <Link to={routes.clientStories}>Client Stories</Link>
            {/*<Link to={routes.techBlog}>Tech Blog</Link>*/}
            <Link to={routes.aboutUs}>About Us</Link>
            <Link to={routes.careers}>Careers</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.linkedin.com/company/aspect-is/">
              <LinkedinIcon />
            </SocialLink>
            <SocialLink href="https://instagram.com/aspect.is">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://www.facebook.com/profile.php?id=100085622666165">
              <FacebookIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright {new Date().getFullYear()}, Aspect.is. All Rights
            Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
