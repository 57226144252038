export const whatWeDo = "/what-we-do";
export const howWeWork = "/how-we-work";
export const clientStories = "/client-stories";
export const techBlog = "/tech-blog";
export const aboutUs = "/about-us";
export const careers = "/careers";
export const contactUs = "/contact-us";

export const job = (jobId) => `/jobs/${jobId}`;

export default {
  whatWeDo,
  howWeWork,
  clientStories,
  techBlog,
  aboutUs,
  careers,
  contactUs,
  job,
};
