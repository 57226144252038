import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import React from "react";

const ImgContainer = tw.div`flex items-center`;
const Img = tw.img`w-48 h-20`;

export default (props) => {
  return (
    <ImgContainer>
      <Img src={props.image} />
    </ImgContainer>
  );
};
