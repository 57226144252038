import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import CardWithShadow from "../cards/CardWithShadow";
import NextIcon from "../../images/nextjs.svg";
import NestIcon from "../../images/nestjs.svg";
import SpringIcon from "../../images/spring.svg";
import ReactIcon from "../../images/react.svg";
import AwsIcon from "../../images/aws.svg";
import NodeIcon from "../../images/nodejs.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

// const DecoratorBlob = styled(SvgDecoratorBlob3)`
//   ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
// `;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: SpringIcon,
      title: "Spring",
      description:
        "We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou.",
    },
    { imageSrc: NestIcon, title: "Nest.js" },
    { imageSrc: NextIcon, title: "Next.js" },
    { imageSrc: ReactIcon, title: "React.js" },
    { imageSrc: NodeIcon, title: "Node.js" },
    { imageSrc: AwsIcon, title: "AWS" },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          Our <span tw="text-primary-500">Technology</span> Expertise
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <CardWithShadow image={card.imageSrc} />
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
