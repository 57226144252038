import { defaultLogoLinkDark, defaultNavDark } from "../../utils/navigation";
import React from "react";
import tw from "twin.macro";
import HeaderBase from "./light";

const Header = tw(HeaderBase)`max-w-none`;

export default ({
  logoLink = defaultLogoLinkDark,
  links = defaultNavDark(),
}) => <Header logoLink={logoLink} links={links} />;
