import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

import { ReactComponent as ClosedEye } from "feather-icons/dist/icons/eye-off.svg";
import routes, { clientStories } from "../../utils/routes";
import { Link } from "react-router-dom";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)((props) => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`,
]);
const CardColumn = tw(
  Column
)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`,
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`flex items-center text-primary-500 font-bold text-lg`;
const CardType = tw.div`flex items-center font-semibold text-xs text-gray-600`;

const NdaIcon = tw(ClosedEye)`h-3`;

const CardTitle = tw.h5`text-xl mt-4 mb-6 font-bold`;

const CardActionLink = tw(
  Link
)`inline-flex justify-center w-full px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;

export default ({
  subheading = "Our Portfolio",
  headingHtmlComponent = (
    <>
      We've done some <span tw="text-primary-500">amazing projects</span>
    </>
  ),
  description = "Share your journey and join us in shaping our remarkable project's legacy.",
  linkText = "View all Projects",
  cardLinkText = "Read Case Study",
  textOnLeft = false,
}) => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1611117775350-ac3950990985?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=768&q=80",
      company: "SaaS Client",
      // type: "SEO Marketing",
      title: "State of the art code generation software",
      nda: true,
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1581315628079-f093bb040803?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
      company: "Music Tech Client",
      // type: "Ad Campaign",
      title: "Music product publishing multi-platform",
      nda: true,
    },
  ];
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <Subheading>{subheading}</Subheading>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
              <PrimaryLink to={clientStories}>
                {linkText} <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardCompany>{card.company}</CardCompany>
                    {card.nda && (
                      <CardType>
                        <NdaIcon />
                        NDA
                      </CardType>
                    )}
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  {/*<CardMeta>*/}
                  {/*  {card.icons.map(({ Icon, label }) => (*/}
                  {/*    <CardMetaFeature>*/}
                  {/*      <Icon /> {label}*/}
                  {/*    </CardMetaFeature>*/}
                  {/*  ))}*/}
                  {/*</CardMeta>*/}
                  <CardActionLink to={routes.clientStories}>
                    {cardLinkText}
                  </CardActionLink>
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
