import { useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import React from "react";

export default () => {
  const { pathname, hash } = useLocation();

  useLayoutEffect(() => {
    const element = hash && document.querySelector(hash);

    if (element) {
      const obs = new ResizeObserver((entries) => {
        if (entries) {
          setTimeout(() => element.scrollIntoView(), 20);

          obs.disconnect();
        }
      });
      obs.observe(element);

      return;
    }
    window.scrollTo(0, 0);
  }, [pathname, hash]);

  return <></>;
};
