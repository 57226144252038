import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";

import { ReactComponent as CodeSandBoxSvgComponent } from "feather-icons/dist/icons/codesandbox.svg";
import { ReactComponent as UserPlusSvgComponent } from "feather-icons/dist/icons/user-plus.svg";
import { ReactComponent as SlidersSvgComponent } from "feather-icons/dist/icons/sliders.svg";
import { Link as RouterLink } from "react-router-dom";
import routes from "../../utils/routes";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const LearnMoreButtonDiv = tw.div`justify-self-end`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs justify-between items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
    svg {
      ${tw`stroke-current text-primary-500 h-10 w-10`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

// const DecoratorBlob = styled(SvgDecoratorBlob3)`
//   ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
// `;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      Component: CodeSandBoxSvgComponent,
      title: "End-to-End Solutions",
      description:
        "You own the business vision - we can take on the project ownership. End-to-end refers to the entire process: from creating a design concept and planning the product development right through to launching the software.",
      linkText: "Learn more",
      routeId: "end-to-end",
    },
    {
      Component: UserPlusSvgComponent,
      title: "Team Augmentation",
      description:
        "Get the best technical talent on-demand without needing to go through the hiring process. Team augmentation can help close the knowledge gap, speed up time to hire and time to market, and provide highly-qualified software engineers.",
      linkText: "Learn more",
      routeId: "augmentation",
    },
    {
      Component: SlidersSvgComponent,
      title: "Technical Consultancy",
      description:
        "Our engineering experts with hands-on expertise can work with your organisation to improve your use of technology and help you achieve business goals. We can save you from making costly digital strategy mistakes.",
      linkText: "Learn more",
      routeId: "consulting",
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          Our Professional <span tw="text-primary-500">Services</span>
        </Heading>
        {cards.map(
          ({ Component, title, description, linkText, routeId }, i) => (
            <Column key={i}>
              <Card>
                <span className="imageContainer">
                  {Component && <Component />}
                </span>
                <span className="textContainer">
                  <span className="title">{title || "Fully Secure"}</span>
                  <p className="description">
                    {description ||
                      "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                  </p>
                </span>
                {linkText && (
                  <LearnMoreButtonDiv>
                    <RouterLink to={`${routes.whatWeDo}#${routeId}`}>
                      <span className="link">
                        <span>{linkText}</span>
                        <ArrowRightIcon className="icon" />
                      </span>
                    </RouterLink>
                  </LearnMoreButtonDiv>
                )}
              </Card>
            </Column>
          )
        )}
      </ThreeColumnContainer>
    </Container>
  );
};
