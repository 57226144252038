import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-16 md:order-first`
    : tw`md:ml-8 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(
  SectionHeading
)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight mt-4`;
const Description = tw.div`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`;

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`;
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`;
const Value = tw.div`font-bold text-primary-500`;
const Key = tw.div`font-medium text-gray-700`;
const SubheadingContainer = tw.span`tracking-widest font-bold text-primary-500`;
const Subheading = tw(SubheadingBase)`text-center md:text-left my-4`;
const HighlightedText = tw.span`text-primary-500`;
const UlStyled = tw.ul`list-disc ml-4`;

export default ({ textOnLeft = false }) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const statistics = [
    // {
    //   key: "Engineers",
    //   value: "10",
    // },
    // {
    //   key: "Years",
    //   value: "2+",
    // },
    // {
    //   key: "Rooms",
    //   value: "2093",
    // },
    // {
    //   key: "Workers",
    //   value: "10347",
    // },
  ];

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc="https://images.unsplash.com/photo-1611117775350-ac3950990985?&auto=format&fit=crop&w=1024&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SubheadingContainer>
              <Subheading>Code Auto-Generation Client Story</Subheading>
            </SubheadingContainer>
            <Heading>
              Code <HighlightedText>automation</HighlightedText> software
            </Heading>
            <Description>
              <Subheading>The Story</Subheading>A system that auto-generates
              code for integration with an API on demand. Ranging from client
              (consumer) code, to documentation, this product enhances developer
              experience when integrating with APIs, resulting in faster and
              wider adoption and retention.
              <Subheading>The Challenges</Subheading>
              <UlStyled>
                <li>Implement software that can generate code</li>
                <li>
                  Support a myriad of quality of life features such as
                  authentication, retry, and multiple environments
                </li>
                <li>Support multiple languages</li>
                <li>Maintain feature parity in multiple languages</li>
                <li>Protect the whole system behind authorization</li>
                <li>Support organizations, members and payments</li>
                <li>Integrate all the moving parts of the system</li>
              </UlStyled>
              <Subheading>The Impact</Subheading>A very easy to use, state of
              the art code generation tool, which not only increased API
              consumption, but also saved resources by automating long, tedious
              and error prone processes. Went from a proof of concept to an in
              production product used by over 30 API owning companies.
            </Description>
            {statistics && (
              <Statistics>
                {statistics.map((statistic, index) => (
                  <Statistic key={index}>
                    <Value>{statistic.value}</Value>
                    <Key>{statistic.key}</Key>
                  </Statistic>
                ))}
              </Statistics>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
