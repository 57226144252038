import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-16 md:order-first`
    : tw`md:ml-8 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(
  SectionHeading
)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight mt-4`;
const Description = tw.div`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`;

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`;
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`;
const Value = tw.div`font-bold text-primary-500`;
const Key = tw.div`font-medium text-gray-700`;
const SubheadingContainer = tw.span`tracking-widest font-bold text-primary-500`;
const Subheading = tw(SubheadingBase)`text-center md:text-left my-4`;
const HighlightedText = tw.span`text-primary-500`;
const UlStyled = tw.ul`list-disc ml-4`;

export default ({ textOnLeft = false }) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const statistics = [
    // {
    //   key: "Engineers",
    //   value: "10",
    // },
    // {
    //   key: "Years",
    //   value: "2+",
    // },
    // {
    //   key: "Rooms",
    //   value: "2093",
    // },
    // {
    //   key: "Workers",
    //   value: "10347",
    // },
  ];

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc="https://images.unsplash.com/photo-1508385082359-f38ae991e8f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80" />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SubheadingContainer>
              <Subheading>Global Business Consultancy</Subheading>
            </SubheadingContainer>
            <Heading>
              Data-intensive{" "}
              <HighlightedText>business intelligence</HighlightedText> software
            </Heading>
            <Description>
              <Subheading>The Story</Subheading>
              Create an automated system for obtaining a user consent and
              exporting, transforming and loading petabytes of NRT (near real
              time) retail data. Continuously store the data to a data lake and
              run various business intelligence analysis queries in order to
              gain valuable insights into possible business decision
              optimizations.
              <Subheading>The Challenges</Subheading>
              <UlStyled>
                <li>Implement industry standard authorization process</li>
                <li>Design the scalable serverless engine architecture</li>
                <li>
                  Optimize cost and performance while handling petabytes of data
                </li>
                <li>Create a highly available and fault tolerant system</li>
                <li>
                  Make the system completely automatic and scheduled, with no
                  human interaction
                </li>
                <li>
                  Understand the complex business domain of online retail data
                </li>
                <li>
                  Design a seamless process for onboarding new users, with just
                  a single email
                </li>
              </UlStyled>
              <Subheading>The Impact</Subheading>
              Long and costly process of onboarding new users and managing their
              retail data was completely changed and automated. Practically no
              human interaction was needed for the onboarding process. Easily
              scalable serverless engine was in place, ready to continuously
              manage data and provide highly valuable business intelligence. The
              cost of the system was negligible. Daily retail data pulls were
              automated and the client could finally focus their workforce on
              the core consultancy business.
            </Description>
            {statistics && (
              <Statistics>
                {statistics.map((statistic, index) => (
                  <Statistic key={index}>
                    <Value>{statistic.value}</Value>
                    <Key>{statistic.key}</Key>
                  </Statistic>
                ))}
              </Statistics>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
