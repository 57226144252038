import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import OurProfessionalServices from "components/features/OurServiceFeatures.js";
import HowWeWork from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import HowToGetStarted from "../components/features/TwoColWithSteps";
import routes from "../utils/routes";

import SolutionSvg from "../images/solution.svg";
import OurExpertiseIllustration from "../images/stand_out.svg";
import { ReactComponent as EyeIcon } from "feather-icons/dist/icons/eye.svg";
import { ReactComponent as AwardIcon } from "feather-icons/dist/icons/award.svg";
import FollowSocials from "../components/cta/FollowSocials";
import ThreeColSlider from "../components/cards/ThreeColSlider";

const Subheading = tw.span`tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;

export default () => (
  <AnimationRevealPage disabled>
    <Hero
      imageUrl={
        "https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80"
      }
    />
    <Portfolio />
    <HowToGetStarted
      subheading={<Subheading>How We Can Help You</Subheading>}
      heading={
        <>
          Easy to <HighlightedText>Get Started</HighlightedText>
        </>
      }
      textOnLeft={false}
      imageSrc={SolutionSvg}
      imageDecoratorBlob={true}
      decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 right-0`}
      steps={[
        {
          heading: "Contact Us",
          description:
            "Give us an overview of the digital challenges your organization is facing or introduce us to your ideas for software products.",
        },
        {
          heading: "Discovery",
          description:
            "We will evaluate the challenges and possible partnership models and propose the best fit for your use case. We will insinuate you to our work methodologies and how we can help guide your digital transformation in most effective way.",
        },
        {
          heading: "Partnership",
          description:
            "We will form a strategic partnership and set ourselves for success. Together we'll align all the details and begin with the continuous value creation.",
        },
      ]}
    />
    <OurProfessionalServices />
    <HowWeWork
      subheading={"Our Expertise"}
      heading={
        <>
          We build trustful relationships and deliver what we promise with focus
          on <span tw="text-primary-500">quality</span>
        </>
      }
      imageSrc={OurExpertiseIllustration}
      description={
        "Aspect was founded in 2022 with a mission to inspire our people to do what inspires them so we can, together, change any aspect for the better."
      }
      features={[
        {
          Icon: EyeIcon,
          title: "Responsibility",
          description:
            "We want to help you do better and we are ready to share the risk.",
        },
        {
          Icon: AwardIcon,
          title: "Quality",
          description: "We make sure quality is in main focus.",
        },
      ]}
      primaryButtonText={"See Our Vision"}
      primaryButtonUrl={routes.howWeWork}
    />
    {/*  TODO: Will be implemented */}
    {/*<Blog />*/}
    <ContactUsForm />
    <FollowSocials />
    <Footer />
  </AnimationRevealPage>
);
