import JobTemplate from "./JobTemplate";
const mailTo =
  "mailto:careers@aspect.is?subject=UI/UX Web Designer Application&body=Hi, I would like to apply for the open UI/UX Web Designer Application. Here is my CV & Portfolio.";
export default () => (
  <JobTemplate
    description={
      "We are a team of highly skilled and experienced professionals working on the forefront of AI technology. We're always on the lookout for top talent. If you're passionate about driving innovative products and thrive in a challenging environment with high expectations, we want to hear from you. We are looking for a UI/UX Web Designer to lead our Design team. You will be responsible for the development of new software products and enhancements of existing products." +
      "In this role, you'll be responsible for crafting intuitive and impactful user experiences for our next-generation AI-driven products. This position is part-time, approximately 20 to 30 hours per week, with flexible working hours."
    }
    title={"UI/UX Web Designer"}
    imageUrl={
      "https://images.unsplash.com/photo-1626785774573-4b799315345d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80"
    }
    roleRequired={[
        "Design highly effective user-focused experiences and interfaces across various platforms, including AI-powered applications, web apps, dashboards, and documentation",
        "Develop and maintain comprehensive design documentation, including wireframes, user flows, information architecture, and interaction design, tailored for AI-driven products",
        "Ability to integrate multiple services and processes into a seamless cross-platform experience, even in unfamiliar environments",
        "Collaborate closely with cross-functional teams and stakeholders to evolve concepts into user-centric features throughout the software development lifecycle",
        "Create and present interactive prototypes, validating designs with product teams, stakeholders, and users, especially in the context of AI applications",
        "Stay informed on industry trends, user experience best practices, and emerging technologies, particularly in AI and related fields"
    ]}
    niceToHave={[
        "Technical understanding of front-end development technologies (HTML, CSS, JavaScript) to facilitate better collaboration with developers",
        "A passion for continuous learning and staying updated on emerging design trends, tools, and technologies, particularly those relevant to AI and innovative user experiences"
    ]}
    mailTo={mailTo}
    mailToText={"Send your CV & Portfolio to careers@aspect.is"}
  />
);
