import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";

import AspectFoundsVerticalImage from "images/aspect_founders_vertical.jpg";
import { defaultLogoLinkDark, defaultNavDark } from "../../utils/navigation";

const Container1 = tw.div`max-w-screen-xl md:flex-row justify-between mx-auto`;

export const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-primary-900 leading-snug xl:text-6xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;

export default ({
  heading = "Better, Faster and Cheaper Cloud.",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  imageSrc,
  imageDecoratorBlob = true,
  primaryButtonUrl = "https://google.com",
  primaryButtonText = "Get Started",
  buttonRounded = true,
  features = [
    "Available in 7 Locations",
    "Premium Internet Backbone",
    "99.99% Uptime SLA",
  ],
  testimonial = {
    quote:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    customerName: "Charlotte Hale",
    customerCompany: "Delos Inc.",
  },
  showHeader = true,
}) => {
  return (
    <Container1>
      {showHeader && (
        <Header logoLink={defaultLogoLinkDark} links={defaultNavDark()} />
      )}
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              {/*<PrimaryButton*/}
              {/*  as="a"*/}
              {/*  href={primaryButtonUrl}*/}
              {/*  css={buttonRoundedCss}*/}
              {/*>*/}
              {/*  {primaryButtonText}*/}
              {/*</PrimaryButton>*/}
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            {imageSrc && (
              <ImageColumn>
                <ImageContainer>
                  <Image src={imageSrc} />
                  {imageDecoratorBlob && <ImageDecoratorBlob />}
                  {/*<Testimonial>*/}
                  {/*  <QuotesLeftIcon />*/}
                  {/*  <Quote>{testimonial.quote}</Quote>*/}
                  {/*  <CustomerName>{testimonial.customerName}</CustomerName>*/}
                  {/*  <CustomerCompany>*/}
                  {/*    {testimonial.customerCompany}*/}
                  {/*  </CustomerCompany>*/}
                  {/*</Testimonial>*/}
                </ImageContainer>
                <Offsetbackground />
              </ImageColumn>
            )}
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </Container1>
  );
};
