import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";

import BackgroundAsImageCareers from "../components/hero/BackgroundAsImageCareers";
import {
  Container,
  ContentWithVerticalPadding,
} from "../components/misc/Layouts";
import { SectionHeading } from "../components/misc/Headings";
import JobPost from "../components/job-post/JobPost";

export const TwoColumn = tw(
  ContentWithVerticalPadding
)`flex flex-col justify-center max-w-screen-xl mx-auto py-10 md:py-12`;
export const CategoryHeading = tw(
  SectionHeading
)`text-center flex items-center text-secondary-900 leading-snug xl:text-3xl`;
export const ContentWrapper = tw(
  ContentWithVerticalPadding
)`flex flex-col justify-center items-center`;

const features = [
  "Hybrid or remote",
  "Flexible hours",
  "Contract or full time",
];

export default () => {
  return (
    <AnimationRevealPage disabled>
      <BackgroundAsImageCareers
        imageUrl={
          "https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80"
        }
      />
      <Container>
        <TwoColumn>
          <ContentWrapper>
            <CategoryHeading>Product</CategoryHeading>
            <JobPost
                heading={"Product Manager"}
                features={features}
                href={"/jobs/product-manager"}
                description="We're seeking an experienced Product Manager to join our dynamic team. Ideal candidates thrive in fast-paced environments and excel at driving the entire product lifecycle, from ideation to launch. You'll work closely with cross-functional teams, including Engineering, Design, and Marketing, to define product requirements, develop roadmaps, and ensure timely delivery. Additionally, you'll conduct market and competitor research to inform product strategies and identify opportunities for differentiation. We're looking for someone who can leverage data-driven insights to make informed decisions, prioritize features, and oversee the execution of product strategies. Familiarity with agile methodologies, user-centered design principles, and the ability to communicate technical concepts to non-technical stakeholders are key to success in this role."
            />
          </ContentWrapper>
          <ContentWrapper>
            <CategoryHeading>Engineering</CategoryHeading>
            <JobPost
              heading={"Senior Full-Stack Engineer"}
              features={features}
              href={"/jobs/senior-full-stack-engineer"}
              description="We're in search of an experienced full-stack software developer to join our dynamic Engineering team. Those who thrive in our environment tend to be versatile developers who can write code across a variety of technology stacks and enjoy contributing to every phase of the software development life cycle. Toolbox for the job mainly includes TypeScript, Nest, Next and AWS. Ideally, we're looking for someone who can design, architect, develop, and deploy code using the latest engineering practices, such as adhering to coding standards, participating in code reviews, managing source control, implementing continuous deployment, testing, and operations."
            />
          </ContentWrapper>
          <ContentWrapper>
            <CategoryHeading>Design</CategoryHeading>
            <JobPost
              heading={"UI/UX Web Designer"}
              features={features}
              href={"/jobs/ui-web-designer"}
              description="We're on the lookout for an experienced UI/UX designer to join our vibrant Design team. Those who excel in our environment are typically creative minds with a knack for crafting engaging user experiences across various platforms and devices. Our toolkit includes tools like Figma, Adobe Photoshop and Adobe XD. Ideally, we're seeking someone who can conceptualize, prototype, iterate, and deliver user-centric designs using the latest UX design principles and methodologies. This includes conducting user research, creating wireframes and mockups, collaborating closely with cross-functional teams, and advocating for the user throughout the design process."
            />
          </ContentWrapper>
        </TwoColumn>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
