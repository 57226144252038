import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link as RouterLink } from "react-router-dom";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
  RouterNavLink,
  PrimaryRouterNavLinkDark,
} from "../headers/light.js";
import routes from "../../utils/routes";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

export default ({
  heading = (
    <>
      <span>
        Meet our passion for <br /> technology and let it <br />{" "}
        <SlantedBackground>shape every aspect </SlantedBackground>
      </span>
      <span> of your business</span>
    </>
  ),
  showContactUs = false,
  primaryAction = <RouterLink to={"/#contactUs"}>Contact us</RouterLink>,
}) => {
  const navLinks = [
    <NavLinks key={1}>
      <RouterNavLink to={routes.whatWeDo}>What We Do</RouterNavLink>
      <RouterNavLink to={routes.howWeWork}>How We Work</RouterNavLink>
      <RouterNavLink to={routes.clientStories}>Client Stories</RouterNavLink>
      {/*<NavLink href="#">Tech Blog</NavLink>*/}
      <RouterNavLink to={routes.aboutUs}>About Us</RouterNavLink>
    </NavLinks>,
    showContactUs && (
      <NavLinks key={2}>
        <PrimaryRouterNavLinkDark to={routes.contactUs}>
          Contact Us
        </PrimaryRouterNavLinkDark>
      </NavLinks>
    ),
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            {primaryAction && <PrimaryAction>{primaryAction}</PrimaryAction>}
          </LeftColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
