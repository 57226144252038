import { Container } from "../../components/misc/Layouts";
import { ContentWrapper, TwoColumn } from "../Careers";
import BackgroundAsImageCareers from "../../components/hero/BackgroundAsImageCareers";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import tw from "twin.macro";
import { PrimaryButton } from "../../components/misc/Buttons";
import Footer from "../../components/footers/MiniCenteredFooter";
import React from "react";

const UlStyled = tw.ul`list-disc ml-4`;
const SectionHeading = tw.h3`text-3xl sm:text-3xl font-black tracking-wide text-left py-4`;

const Content = tw(
  ContentWrapper
)`py-2 text-left mx-auto max-w-3xl sm:text-left items-start`;
const Description = tw.p``;
const Section = tw.p`mt-8`;
const ApplyNow = tw(PrimaryButton)`mt-8 inline-block`;

const ApplyLink = tw.a`text-primary-500 underline`;

export default ({
  title = "Work with the best",
  description = "We are a team of highly skilled and experienced professionals working on the forefront of AI technology. We're always on the lookout for top talent. If you're passionate about driving innovative products and thrive in a challenging environment with high expectations, we want to hear from you. We're in search of an experienced full-stack software developer to join our dynamic Engineering team. Those who thrive in our environment tend to be versatile developers who can write code across a variety of technology stacks and enjoy contributing to every phase of the software development life cycle. Toolbox for the job mainly includes TypeScript, Nest, Next and AWS. Ideally, we're looking for someone who can design, architect, develop, and deploy code using the latest engineering practices, such as adhering to coding standards, participating in code reviews, managing source control, implementing continuous deployment, testing, and operations.",
  whatWeOffer = [
    "Opportunity to work with the best in the industry",
    "Competitive salary",
    "Two paid luxury 5 day trips abroad every year",
    'Working equipment MacBook Pro 16" M3',
    "Flexible working hours",
    "Remote work",
    "Healthcare",
    "Opportunities for growth",
  ],
  required = [
    "Passion for creating high-quality products",
    "High level of ownership and problem-solving skills",
    "Strong team collaboration abilities",
    "Excellent communication skills in English",
    "Customer-focused approach",
  ],
  roleRequired = [
    "Proficiency with Typescript, React.js, Node.js, Nest.js, Next.js, Restful APIs, Github Actions, Docker and AWS",
    "Designing, developing, deploying, and maintaining efficient code across a variety of programming languages, libraries and tools",
    "Ability to stitch together many different services and processes even if you have not worked with them before",
    "Uphold best practices in engineering, security, and design",
    "Crafting and refining onboarding experiences to help users get to what they want faster",
  ],
  niceToHave = [
    "Leaning towards FE",
    "Experience and/or interest for AI",
    "Experience in Python (fastapi)",
    "A passion for learning and staying updated on emerging technologies and industry trends",
  ],
  imageUrl = "https://images.unsplash.com/photo-1579882392185-581038fbc8c5?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80",
  mailTo = "mailto:careers@aspect.is",
  mailToText = "Send your CV to careers@aspect.is"
}) => {
  return (
    <AnimationRevealPage disabled>
      <BackgroundAsImageCareers imageUrl={imageUrl} heading={title} />
      <Container>
        <TwoColumn>
          <Content>
            <Section>
              <SectionHeading>Description</SectionHeading>
              <Description>{description}</Description>
            </Section>
            <Section>
              <SectionHeading>What we offer</SectionHeading>
              <UlStyled>
                {whatWeOffer.map((item) => (
                  <li>{item}</li>
                ))}
              </UlStyled>
            </Section>
            <Section>
              <SectionHeading>General requirements</SectionHeading>
              <UlStyled>
                {required.map((item) => (
                  <li>{item}</li>
                ))}
              </UlStyled>
            </Section>
            <Section>
              <SectionHeading>Role requirements</SectionHeading>
              <UlStyled>
                {roleRequired.map((item) => (
                  <li>{item}</li>
                ))}
              </UlStyled>
            </Section>
            <Section>
              <SectionHeading>Nice to have</SectionHeading>
              <UlStyled>
                {niceToHave.map((item) => (
                  <li>{item}</li>
                ))}
              </UlStyled>
            </Section>
            <Section>
              <SectionHeading>Next steps</SectionHeading>
              <div>
                <ApplyLink href={mailTo}>
                  {mailToText}
                </ApplyLink>{" "}
                alongside the possible date of joining and your salary
                expectations or click the button below:
              </div>

              <ApplyNow as="a" href={mailTo}>
                Apply Now
              </ApplyNow>
            </Section>
          </Content>
        </TwoColumn>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
