import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";

import StevanKapicicImage from "images/stevan_kapicic_600x600.png";
import SkosImage from "images/skos_profile_600x600.png";
import AboutUsPerson from "../components/features/AboutUsPerson";
import TwoColumnWithFeaturesAndTestimonialAboutUs from "../components/hero/TwoColumnWithFeaturesAndTestimonialAboutUs";
import AspectFoundsVerticalImage from "../images/aspect_founders_vertical.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const currentYear = new Date().getFullYear();

const kosijerExperienceInYears = currentYear - 2017;
const kapicicExperienceInYears = currentYear - 2016;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <TwoColumnWithFeaturesAndTestimonialAboutUs
        description={
          "We are engineers. Passionate, ethical, and methodical in our work. Together we have over a decade of experience in software development and have solved immense technical and business challenges. Our professionalism and excellence are what sets us apart from others and are our guarantee. We are brave enough to strive for perfection in our work, processes, relationships, and life."
        }
        heading="About us"
        features={[
          "Sharp minds, great vibes",
          "Highly experienced",
          "Highly motivated",
          "Easy and fun to work with",
        ]}
        imageSrc={AspectFoundsVerticalImage}
      />
      <AboutUsPerson
        subheading={
          <Subheading>
            <span tw="text-secondary-500"> Co-founder & </span>
            <span tw="text-primary-500">CTO</span>
          </Subheading>
        }
        heading={"Stevan Kapičić"}
        textOnLeft={false}
        imageSrc={StevanKapicicImage}
        description={`Senior full-stack software engineer, ex-staff, and tech lead - over ${kapicicExperienceInYears} years of experience in most in-demand web languages, including Javascript, Java, and PHP. Mentored and guided individuals and teams in areas of technical capability. Improved numerous processes with the effect of increased development velocity and code quality. From a perspective of a good engineering mindset, has the complete understanding of client problems, from every aspect. Brings great value with innovative solutions and outside-of-the-box thinking.`}
        statistics={[
          {
            key: "Projects",
            value: "20+",
          },
          {
            key: "Technologies",
            value: "10+",
          },
          {
            key: "Years of Experience",
            value: `${kapicicExperienceInYears}+`,
          },
        ]}
      />
      <AboutUsPerson
        subheading={
          <Subheading>
            <span tw="text-secondary-500"> Co-founder & </span>
            <span tw="text-primary-500">CEO</span>
          </Subheading>
        }
        imageSrc={SkosImage}
        textOnLeft={true}
        heading={"Stevan Kosijer"}
        description={`Senior full-stack software engineer, team lead - over ${kosijerExperienceInYears} years of experience, provides the most value working on backend development, architecture and infrastructure, databases, cloud, and serverless systems. Worked with large enterprise software solutions that often had the high infrastructure and business domain complexity. Thriving in unfamiliar situations and using opportunities to learn and gain exposure to new ideas and experiences is something Stevan looks forward to. He always tends to stay curious and informed in today's fast pace world.`}
        statistics={[
          {
            key: "Projects",
            value: "20+",
          },
          {
            key: "Teams led",
            value: "5+",
          },
          {
            key: "Years of Experience",
            value: `${kosijerExperienceInYears}+`,
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
