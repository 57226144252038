import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import HeaderDark from "../components/headers/dark";
import FollowSocials from "../components/cta/FollowSocials";
import { defaultNavDark } from "../utils/navigation";

const Container = tw.div`max-w-screen-xl md:flex-row justify-between mx-auto`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Container>
        <HeaderDark links={defaultNavDark(false)} />
        <ContactUsForm />
        <FollowSocials />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
