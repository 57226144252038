import routes from "./routes";
import React from "react";
import {
  LogoLink,
  NavLinks,
  PrimaryRouterNavLink,
  RouterNavLink,
} from "../components/headers/light";
import tw from "twin.macro";
import { ReactComponent as AspectLogoSvg } from "images/aspect-logo.svg";

const RouterNavLinkDark = tw(RouterNavLink)`text-primary-800`;
const LogoLinkDark = tw(LogoLink)`font-bold text-primary-800`;
const LogoLinkLight = tw(LogoLink)`font-bold text-white`;
const AspectLogo = tw(AspectLogoSvg)`w-12 h-12 mr-1`;
const CtaDark = tw(PrimaryRouterNavLink)`text-white bg-primary-800`;

export const defaultNavDark = (withContactUs = true) => (
  <>
    <NavLinks key={1}>
      <RouterNavLinkDark to={routes.whatWeDo}>What We Do</RouterNavLinkDark>
      <RouterNavLinkDark to={routes.howWeWork}>How We Work</RouterNavLinkDark>
      <RouterNavLinkDark to={routes.clientStories}>
        Client Stories
      </RouterNavLinkDark>
      {/*<NavLink href="#">Tech Blog</NavLink>*/}
      <RouterNavLinkDark to={routes.aboutUs}>About Us</RouterNavLinkDark>
      <RouterNavLinkDark to={routes.careers}>Careers</RouterNavLinkDark>
    </NavLinks>
    {withContactUs && (
      <NavLinks key={2}>
        <CtaDark to={routes.contactUs}>Contact Us</CtaDark>
      </NavLinks>
    )}
  </>
);

export const defaultLogoLinkDark = (
  <LogoLinkDark to="/">
    <AspectLogo />
    Aspect
  </LogoLinkDark>
);

export const defaultLogoLink = (
  <LogoLinkLight to="/">
    <AspectLogo />
    Aspect
  </LogoLinkLight>
);

export const defaultNav = (
  <NavLinks key={1}>
    <RouterNavLink to={routes.whatWeDo}>What We Do</RouterNavLink>
    <RouterNavLink to={routes.howWeWork}>How We Work</RouterNavLink>
    <RouterNavLink to={routes.clientStories}>Client Stories</RouterNavLink>
    {/*<NavLink href="#">Tech Blog</NavLink>*/}
    <RouterNavLink to={routes.aboutUs}>About Us</RouterNavLink>
  </NavLinks>
);
