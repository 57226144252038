import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-16 md:order-first`
    : tw`md:ml-8 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(
  SectionHeading
)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight mt-4`;
const Description = tw.div`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`;

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`;
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`;
const Value = tw.div`font-bold text-primary-500`;
const Key = tw.div`font-medium text-gray-700`;
const SubheadingContainer = tw.span`tracking-widest font-bold text-primary-500`;
const Subheading = tw(SubheadingBase)`text-center md:text-left my-4`;
const HighlightedText = tw.span`text-primary-500`;
const UlStyled = tw.ul`list-disc ml-4`;

export default ({ textOnLeft = false }) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const statistics = [
    // {
    //   key: "Engineers",
    //   value: "10",
    // },
    // {
    //   key: "Years",
    //   value: "2+",
    // },
    // {
    //   key: "Rooms",
    //   value: "2093",
    // },
    // {
    //   key: "Workers",
    //   value: "10347",
    // },
  ];

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc="https://images.unsplash.com/photo-1581315628079-f093bb040803?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80" />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SubheadingContainer>
              <Subheading>Music Tech Client</Subheading>
            </SubheadingContainer>
            <Heading>
              Music product publishing{" "}
              <HighlightedText>multi-platform</HighlightedText>
            </Heading>
            <Description>
              <Subheading>The Story</Subheading>
              Help artists all around the world to monetize their songs being
              used in audio mixes. Develop a platform for processing large audio
              files, that uses a famous audio sample matching API to identify
              songs. The matched songs are cut from the original mix and
              published as individual products on various digital service
              providers. The published products could be shared and listened to
              by the global population. The revenue generated by the products is
              monitored and analyzed so that the authors of the songs could be
              compensated accordingly.
              <Subheading>The Challenges</Subheading>
              <UlStyled>
                <li>
                  Integrations with third party APIs which were under VPN
                  protection and not yet public
                </li>
                <li>
                  Making the system fault tolerant when the APIs didn’t return
                  correct results
                </li>
                <li>
                  Fit strict product publishing rules of various digital service
                  providers
                </li>
                <li>
                  Design a UI which had to present a lot of information without
                  overwhelming for the user
                </li>
                <li>
                  Optimize the processing algorithm so that users have a smooth
                  experience
                </li>
                <li>
                  Provide a consistent way of analyzing revenue data and
                  compensating authors
                </li>
              </UlStyled>
              <Subheading>The Impact</Subheading>
              The solution automized what was previously a manual process. The
              pace of the product creation and publishing was accelerated by a
              few dozen times resulting in multiplied revenue. Userbase started
              growing three times faster than before because the core business
              headache was finally resolved.
            </Description>
            {statistics && (
              <Statistics>
                {statistics.map((statistic, index) => (
                  <Statistic key={index}>
                    <Value>{statistic.value}</Value>
                    <Key>{statistic.key}</Key>
                  </Statistic>
                ))}
              </Statistics>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
