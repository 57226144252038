import JobTemplate from "./JobTemplate";
const mailTo =
  "mailto:careers@aspect.is?subject=Product Manager Application&body=Hi, I would like to apply for the open Product Manager Position. Here is my CV.";
export default () => (
  <JobTemplate
    title={"Product Manager"}
    description={"We are a team of highly skilled and experienced professionals working on the forefront of AI technology. We're always on the lookout for top talent. If you're passionate about driving innovative products and thrive in a challenging environment with high expectations, we want to hear from you. We're seeking an experienced Product Manager to join our dynamic Product team. Ideal candidates thrive in fast-paced environments and excel at driving the entire product lifecycle, from ideation to launch. You'll work closely with cross-functional teams, including Engineering, Design, and Marketing, to define product requirements, develop roadmaps, and ensure timely delivery. Additionally, you'll conduct market and competitor research to inform product strategies and identify opportunities for differentiation. We're looking for someone who can leverage data-driven insights to make informed decisions, prioritize features, and oversee the execution of product strategies. Familiarity with agile methodologies, user-centered design principles, and the ability to communicate technical concepts to non-technical stakeholders are key to success in this role."}
    roleRequired={[
      "Experience leading cross-functional teams in a product management capacity",
      "Technical background with experience working closely with engineering teams on modern software development practices and tools",
      "Ability to define and prioritize product roadmaps based on market research, customer feedback, and competitive analysis",
      "Proficiency in overseeing product development from ideation to launch, ensuring alignment with business goals",
      "Familiarity with AI and emerging technologies, with a passion for staying updated on industry trends",
      "Strong problem-solving skills with the ability to make data-driven decisions and navigate complex challenges",
      "Ability to translate technical concepts to non-technical stakeholders and communicate effectively across teams"
    ]}
    niceToHave={[
      "Experience with AI frameworks or a strong interest in AI technology",
      "A passion for learning and staying ahead of the curve in technology and product management practices"
    ]}
    imageUrl={
      "https://images.unsplash.com/photo-1512758017271-d7b84c2113f1?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    }
    mailTo={mailTo}
  />
);
